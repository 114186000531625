import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Etusivu",
    component: Home
  },
  {
    path: "/majoitus",
    name: "Majoitus",
    component: () =>
      import(/* webpackChunkName: "majoitus" */ "../views/Majoitus.vue")
  },
  {
    path: "/majoitus/pyorohirsimokki",
    name: "Pyöröhirsimökki",
    component: () =>
      import(/* webpackChunkName: "majoitus" */ "../views/Pyorohirsimokki.vue")
  },
  {
    path: "/majoitus/hoylahirsimokki",
    name: "Höylähirsimökki",
    component: () =>
      import(/* webpackChunkName: "majoitus" */ "../views/Hoylahirsimokki.vue")
  },
  {
    path: "/majoitus/piilopirtti",
    name: "Piilopirtti",
    component: () =>
      import(/* webpackChunkName: "majoitus" */ "../views/Piilopirtti.vue")
  },
  {
    path: "/tekemista",
    name: "tekemista",
    component: () =>
      import(/* webpackChunkName: "tekemista" */ "../views/Tekemista.vue")
  },
  {
    path: "/yhteystiedot",
    name: "yhteystiedot",
    component: () =>
      import(/* webpackChunkName: "yhteystiedot" */ "../views/Yhteystiedot.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
