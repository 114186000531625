<template>
  <p class="content" :class="{ ingressi: ingressi }">{{ content }}</p>
</template>

<script>
export default {
  name: "Content",
  props: {
    content: {
      type: String,
      required: true
    },
    ingressi: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
@import '../styles/variables.styl';

.content {
  margin: 0 auto 20px;
  width: containers.small;
  max-width: 96%;
  text-align: left;
  line-height: 1.7rem;

  &.ingressi {
    font-family: fonts.serif;
    font-size: 130%;
    line-height: 1.4;
    // max-width: 700px;
    text-align: center;
    margin-bottom: 50px;
  }
}
</style>
