<template>
  <div>
    <VueGallery :images="limages" :index="index" @close="index = null" />
    <div class="gallery" :class="`columns_${columns}`">
      <div
        v-for="(image, imageIndex) in images"
        :key="imageIndex"
        class="gallery__element"
      >
        <img
          :src="getImage(image.src)"
          :alt="image.alt"
          :loading="{ lazy: lazy }"
          :key="imageIndex"
          @click="index = imageIndex"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueGallery from "vue-gallery";

export default {
  name: "Gallery",
  components: {
    VueGallery
  },
  props: {
    images: {
      type: Array,
      required: true
    },
    columns: {
      type: Number,
      default: 3
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      limages: [],
      index: null
    };
  },
  beforeMount: function() {
    this.images.forEach(image => {
      this.limages.push(this.getImage(image.src));
    });
  },
  methods: {
    getImage(name) {
      const images = require.context("../assets/", false, /.$/);
      return images("./" + name);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
@import '../styles/variables.styl';

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0 5px;

  &__element {
    margin: 5px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 0 3px colors.black;
    border-radius: 3px;

    .columns_1 & {
      flex-basis: calc(100% - 10px);
      width: calc(100% - 10px);
      padding-top: calc(100% - 10px);
    }

    .columns_2 & {
      flex-basis: calc(50% - 10px);
      width: calc(50% - 10px);
      padding-top: calc(50% - 10px);
    }

    .columns_3 & {
      flex-basis: calc(33.33% - 10px);
      width: calc(33.33% - 10px);
      padding-top: calc(33.33% - 10px);
    }

    .columns_4 & {
      flex-basis: calc(25% - 10px);
      width: calc(25% - 10px);
      padding-top: calc(25% - 10px);
    }

    @media (max-width: 768px) {
      flex-basis: calc(100% - 10px) !important;
      width: calc(100% - 10px) !important;
      padding-top: calc(100% - 10px) !important;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      transition: all 400ms ease-in;
      backface-visibility: hidden;
    }

    &:hover {
      img {
        transform: scale(1.05);
        transition: all 400ms ease-out;
      }
    }
  }
}
</style>
