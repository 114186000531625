<template>
  <div class="separator" :class="color"></div>
</template>

<script>
export default {
  name: "Separator",
  props: {
    color: {
      type: String,
      default: null
    },
    wide: {
      type: Boolean,
      default: true
    },
    height: {
      type: String,
      default: null
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
@import '../styles/variables.styl';

.separator {
  margin: 40px 20px;
  height: 3px;

  &.black {
    background-image: linear-gradient(to right, rgba(colors.black, 0), rgba(colors.black, 1), rgba(colors.black, 0));
  }

  &.orange {
    background-image: linear-gradient(to right, rgba(colors.orange, 0), rgba(colors.orange, 1), rgba(colors.orange, 0));
  }
}
</style>
