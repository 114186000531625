<template>
  <div class="media_text" :class="{ loaded: loaded, mediaFirst: mediaFirst }">
    <div class="media_text__content">
      <h2
        class="media_text__header"
        v-html="header"
        v-if="header !== null"
      ></h2>
      <div class="media_text__text" v-html="text"></div>
      <Button class="media_text__btn" :link="link" v-if="link !== null" />
    </div>
    <div class="media_text__media">
      <img :src="getImage(img.name)" :alt="img.alt" loading="lazy" />
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
  name: "MediaText",
  components: {
    Button
  },
  props: {
    header: {
      type: String,
      default: null
    },
    text: {
      type: String,
      required: true
    },
    img: {
      type: Object,
      required: true
    },
    mediaFirst: {
      type: Boolean,
      default: false
    },
    link: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loaded: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 200);
  },
  methods: {
    getImage(name) {
      const images = require.context("../assets/", false, /.$/);
      return images("./" + name);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
@import '../styles/variables.styl';

.media_text {
  display: flex;
  width: containers.medium;
  max-width: 96%;
  margin: 50px auto;
  box-shadow: 0 0 6px colors.black;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  &__content {
    box-sizing: border-box;
    flex-basis: 50%;
    width: 50%;
    padding: 50px 30px;
    background-color: colors.black;

    @media (max-width: 768px) {
      flex-basis: 100%;
      width: 100%;
    }

    .mediaFirst & {
      order: 2;
    }
  }

  &__header {
    margin-top: 0;

    #app & {
      color: colors.white;
    }
  }

  &__text {
    font-family: fonts.serif;
    color: colors.white;
    font-size: 18px;

    >>> h3,
    >>> h4 {
      color: colors.white !important;
    }

    >>> ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
      }
    }

    >>> a {
      text-decoration: none;
      padding: 8px 0;
      color: colors.orange;
      font-size: 22px;
      line-height: 30px;
    }
  }

  &__btn {
    margin-top: 30px;
  }

  &__media {
    flex-basis: 50%;
    width: 50%;
    height: auto;
    background-color: colors.black;
    position: relative;

    @media (max-width: 768px) {
      flex-basis: 100%;
      width: 100%;
    }

    .mediaFirst & {
      order: 1;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;

      @media (max-width: 768px) {
        position: relative;
        display: block;
      }
    }
  }
}
</style>
