<template>
  <div class="footer">
    <div class="footer__content">
      <div class="footer__text">Soita koska vaan!</div>
      <a href="tel:+358400300959">0400 300 959</a>
      <div class="footer__text">Tai näpyttele viestiä:</div>
      <a href="mailto:mikko.kilpimaa@gmail.com">mikko.kilpimaa@gmail.com</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    msg: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
@import '../styles/variables.styl';

.footer {
  padding: 50px 0;
  background-color: colors.black;
  border-top: 6px solid colors.orange;
  margin-top: 80px;

  &__content {
    width: containers.large;
    max-width: 96%;
    margin: 0 auto;
    color: colors.white;

    a {
      color: colors.orange;
      font-weight: 700;
      font-size: 22px;
      line-height: 36px;
      text-decoration: none;
      margin-bottom: 20px;
      display: inline-block;
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0px;
        left: -4px;
        width: calc(100% + 8px);
        height: 2px;
        background-color: colors.orange;
        opacity: 0;
        transition: all 300ms ease-in;
      }

      &:hover {
        &:after {
          transition: all 300ms ease-out;
          opacity: 1;
          bottom: -4px;
        }
      }
    }
  }

  &__text {
    color: colors.white;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }
}
</style>
