<template>
  <div class="home">
    <Hero :img="img" :title="title" :text="text" />
    <Content :content="ingressi" :ingressi="true" />
    <Separator color="orange" />
    <h2>{{ majoitusHeader }}</h2>
    <Columns :columns="columns" />
    <MediaText :text="firstText" :img="liftImg" />
    <h3>{{ leirintaHeader }}</h3>
    <Content :content="leirintaText" />
    <Separator color="orange" />
    <h3>{{ aktiviteettiHeader }}</h3>
    <MediaText :text="secondText" :img="fishImg" :mediaFirst="true" />
    <Content :content="thirdText" />
    <Content :content="fourthText" />
    <h3>{{ tunnelmaHeader }}</h3>
    <Gallery :images="images" columns="4" />
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/Hero.vue";
import Content from "@/components/Content.vue";
import Columns from "@/components/Columns.vue";
import MediaText from "@/components/MediaText.vue";
import Separator from "@/components/Separator.vue";
import Gallery from "@/components/Gallery.vue";

export default {
  name: "home",
  components: {
    Hero,
    Content,
    Columns,
    MediaText,
    Separator,
    Gallery
  },
  data() {
    return {
      title: "Marjasuvannon lomamajat",
      majoitusHeader: "Majoitus",
      leirintaHeader: "Leirintäalue, telttapaikat ja aitta",
      aktiviteettiHeader: "Tekemistä ja kokemista",
      img: {
        name: "marjasuvannon-lomamajat.jpg",
        alt: ""
      },
      liftImg: {
        name: "sauna-ja-laavu.jpg",
        alt: "Sauna ja laavu Kemijoen rannalla"
      },
      fishImg: {
        name: "saalis.jpg",
        alt: "Nuotiokalaa"
      },
      text: null,
      ingressi:
        "Marjasuvannon Lomamajat on perinteikäs, pieni perheyritys luonnonkauniilla paikalla Kemijoen varrella Savukosken Viitarannalla. Lomamajoilta löytyy majoittumiseen erähenkisiä mökkejä, jotka kaikki sijaitsevat veden äärellä, Kemijoen ja Ison Akanjärven rannalla. Kotoisan mökkimajoituksen lisäksi tilalla on pieni sähköistetty leirintäalue asuntovaunu- ja telttapaikkoineen.",

      firstText:
        "Marjasuvanto on pieni, rauhallinen ja hyvästä palvelustaan tunnettu paikka, josta monille on muodostunut ”tukikohta” jossa käydään akkuja lataamassa vuosittain, jotkut jopa useamman kerran vuoden aikana. Vieraskirjoista vuosien varrella on löytynyt mm kommentti ”Marjasuvanto, Lapin Paratiisi”. Monet meillä vierailevat varaavat käydessään jo seuraavalle vuodelle majoituksensa, useilla on se oma lempimökki joka halutaan varmistaa etukäteen.",

      leirintaText:
        "Päärakennuksen läheisyydessä on lumettomana aikana pieni sähköistetty leirintäalue, maksimissaan neljälle asuntoautolle tai vaunulle kerrallaan. Vaunualueelta löytyy vesipiste, puucee ja jätteiden tyhjennyspaikka. Puulämmitteinen rantasauna on mökkiläisten kanssa yhteiskäytössä ja Kemijoen rannalla oleva tulistelulaavu on vapaassa käytössä. Telttailijoille löytyy paikkoja jokaiseen makuun, halutessaan voi telttansa laittaa vaikka Kemijoen rantaan vesirajaan ja nukahtaa kuunnellen yöllisen joen ääniä. Leirintäalueelta löytyy myös majoitukseen tunnelmallinen vanha aitta, jossa kesäyö menee mukavasti rankisen alla köllötellen.",

      secondText:
        "Luonnossa liikkumiseen, marjastukseen, kalastukseen, metsästykseen tai vaikka vaan oleiluun tila tarjoaa mukavat puitteet. Tilalta löytyy merkittyjä retkeilyreittejä tulistelupaikkoineen, veneitä vesillä liikkumiseen ja opaspalveluita mihin tahansa luontoaktiviteettiin. Lähiseudun parhaat marjapaikat, kalavedet ja riistamaat asiantuntevan oppaan kanssa antavat varmasti enemmän elämyksiä kuin moni osaa odottaakaan.",

      thirdText:
        "Tilan kotieläimiä pääsee isäntäväen mukana katsomaan päivittäisten askareiden lomassa ja rantasaunoja saa lämmittää vaikka keskellä päivää niin halutessaan. Tilan töihin saa aina osallistua, kaikki loppuu aikanaan, halonteko ei milloinkaan.",

      fourthText:
        "Kaikkia vieraitamme palvellaan yksilöllisesti, järjestämme tarvittaessa kuljetukset vaikka lentoasemalta, täytämme jääkaapin valmiiksi, hoidamme kanootit tai maastopyörät mökin pihaan, oppaan ja toimivan koiran lintu- tai hirvijahtiin, viemme vaikka koiravaljakkoajelulle tai moottorikelkkareissulle talvella, monenmoisia mahdollisuuksia löytyy kun vaan osaa kysyä!",

      columns: [
        {
          img: {
            name: "haukkala_s.jpg",
            alt: "Haukkala - pyötöhirsimökki"
          },
          name: "Haukkala",
          text: "",
          link: {
            name: "Tutustu ja ihastu",
            path: "/majoitus/pyorohirsimokki"
          }
        },
        {
          img: {
            name: "pollola_s.jpg",
            alt: "Pöllölä - Höylähirsimökki"
          },
          name: "Pöllölä",
          text: "",
          link: {
            name: "Tutustu ja ihastu",
            path: "/majoitus/hoylahirsimokki"
          }
        },
        {
          img: {
            name: "riekkola.jpeg",
            alt: "Riekkola - Piilopirtti"
          },
          name: "Riekkola",
          text: "",
          link: {
            name: "Tutustu ja ihastu",
            path: "/majoitus/piilopirtti"
          }
        }
      ],
      tunnelmaHeader: "Tunnelmia",
      images: [
        {
          src: "auringonlasku.jpg",
          alt: "Auringonlasku"
        },
        {
          src: "yhteystiedot.jpg",
          alt: "Ilta kalalla"
        },
        {
          src: "maisemaa.jpg",
          alt: "Maisema"
        },
        {
          src: "maisemaa5.jpg",
          alt: "Maisema"
        },
        {
          src: "tunnelmaa3.jpg",
          alt: "Tunnelmia iltanuotiolla"
        },
        {
          src: "lampaat2.jpg",
          alt: "Lampaat pihalla"
        },
        {
          src: "maisemaa6.jpg",
          alt: "Maisema"
        },
        {
          src: "maisemaa7.jpg",
          alt: "Maisema"
        },
        {
          src: "kalalla_joella.jpg",
          alt: "Kalalla joella"
        },
        {
          src: "kalalla_venessa.jpg",
          alt: "Kalalla veneessä"
        },
        {
          src: "sumuinen_joki.jpg",
          alt: "Sumua joella"
        },
        {
          src: "monkkari.jpg",
          alt: "Mönkkäri"
        },
        {
          src: "soutujuttuja.jpg",
          alt: "soutelua"
        },
        {
          src: "minka_ja_mansikat.jpg",
          alt: "Mansikkaa"
        },
        {
          src: "heinaa.jpg",
          alt: "Heinähommia"
        },
        {
          src: "talvipiha.jpeg",
          alt: "Talvipiha"
        }
      ]
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
@import '../styles/variables.styl';
</style>
