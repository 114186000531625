<template>
  <div class="columns">
    <div v-for="(item, index) in columns" :key="index" class="column">
      <div class="column__img">
        <img
          v-if="item.img !== null"
          :src="getImage(item.img.name)"
          :alt="item.img.alt"
        />
      </div>
      <div class="column__content">
        <h2>{{ item.name }}</h2>
        <p>{{ item.text }}</p>
        <Button :link="item.link" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
  name: "Columns",
  components: {
    Button
  },
  props: {
    columns: {
      type: Array,
      required: true
    }
  },
  mounted() {
    // console.log("columns", this.columns);
  },
  methods: {
    getImage(name) {
      const images = require.context("../assets/", false, /.$/);
      return images("./" + name);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
@import '../styles/variables.styl';

.columns {
  display: flex;
  width: containers.large;
  max-width: 96%;
  margin: 50px auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .column {
    margin: 0 20px;
    width: 100%;
    box-shadow: 0 0 8px rgba(colors.black, 0.7);
    border-radius: 3px;

    @media (max-width: 768px) {
      margin: 0 0 20px;
    }

    &:first-of-type {
      // margin: 0 20px 0 0;
    }

    &:last-of-type {
      // margin: 0 0 0 20px;
    }

    &__img {
      height: 200px;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    &__content {
      padding: 20px;
    }

    h2 {
      text-align: center;
      margin-top: 0;
    }
  }
}
</style>
