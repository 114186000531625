<template>
  <div class="hero" :class="{ loaded: loaded }">
    <div v-if="img !== null" class="hero__img">
      <img :src="getImage(img.name)" :alt="img.alt" loading="lazy" />
    </div>
    <div class="hero__content">
      <h1 v-html="title"></h1>
      <div v-if="text !== null" class="hero__ingress">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hero",
  props: {
    img: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loaded: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.loaded = true;
    }, 200);
  },
  methods: {
    getImage(name) {
      const images = require.context("../assets/", false, /.$/);
      return images("./" + name);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
@import '../styles/variables.styl';

.hero {
  width: 100%;
  height: 400px;
  position: relative;
  margin-bottom: 50px;
  box-shadow: 0 0 20px colors.black;

  &__img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: -1;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transform: scale(1.15);
      filter: blur(3px);
      transition: all 1200ms ease-out 800ms;

      .loaded & {
        transform: scale(1);
        filter: blur(0);
      }
    }
  }

  &__content {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: colors.black;
    text-shadow: 2px 2px 8px colors.white;
    max-width: 700px;
    font-weight: 700;

    h1 {
      // transition: all 800ms ease-in 200ms;
      font-size: 42px;
      // transform: scale(0.2);
      // opacity: 0;
    }
  }

  &__ingress {
    font-size: 18px;
  }
}
</style>
