<template>
  <a :href="link.path" class="button">
    <span>{{ link.name }}</span>
  </a>
</template>

<script>
export default {
  name: "Button",
  props: {
    link: {
      type: Object,
      required: true
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
@import '../styles/variables.styl';

.button {
  display: inline-block;
  background-color: colors.orange;
  border: 2px solid colors.orange;
  border-radius: 3px;
  text-decoration: none;
  transition: all 300ms ease-in;

  span {
    color: colors.white;
    font-weight: 700;
    text-transform: uppercase;
    padding: 12px 24px;
    display: inline-block;
    transition: all 300ms ease-in;
  }

  &:hover {
    transition: all 300ms ease-out;
    background-color: colors.white;

    span {
      transition: all 300ms ease-out;
      color: colors.orange;
    }
  }
}
</style>
