<template>
  <div id="app">
    <Header :name="siteName" :nav="nav" />
    <transition name="page-transition">
      <router-view class="main" />
    </transition>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "app",
  components: {
    Header,
    Footer
  },
  data: function() {
    return {
      siteName: "Marjasuvannon lomamajat",
      nav: [
        {
          name: "Etusivu",
          path: "/"
        },
        {
          name: "Majoitus",
          path: "/majoitus"
        },
        {
          name: "Tekemistä",
          path: "/tekemista"
        },
        {
          name: "Yhteystiedot",
          path: "/yhteystiedot"
        }
      ]
    };
  }
};
</script>

<style lang="stylus">
@import './styles/variables.styl';

body {
  margin: 0;
}

#app {
  font-family: fonts.sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  h1, h2, h3, h4, h5, h6, .highlight {
    font-family: fonts.serif;
    color: colors.black;

    &.color-orange {
      color: colors.orange;
    }
  }

  a.close {
    font-size: 50px;
    color: colors.orange;
    opacity: 1;
  }
}

.page-transition-leave-active {
  transition: all 300ms ease-in;
}

.page-transition-leave {
  opacity: 0;
}

.page-transition-enter-active {
  opacity: 1;
  transition: all 300ms ease-out;
}

.page-transition-enter {
  opacity: 0;
}
</style>
