<template>
  <div class="header">
    <div class="header__containter">
      <div class="header__logo">
        <router-link to="/">{{ name }}</router-link>
      </div>
      <div
        class="mobileNavToggle"
        :class="{ open: isMobileNavOpen }"
        v-if="isMobile"
        @click="isMobileNavOpen = !isMobileNavOpen"
      >
        <span></span>
      </div>
      <ul
        id="nav"
        :class="{
          mobileNav: isMobile,
          mobileNavOpen: isMobileNavOpen
        }"
        v-if="!isMobile || isMobileNavOpen"
      >
        <li v-for="(item, key) in nav" :key="key">
          <router-link :to="item.path">
            <span v-on:click="closeNav">{{ item.name }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import "@/styles/variables.styl";
export default {
  name: "Header",
  props: {
    name: String,
    nav: Array
  },
  mounted: function() {
    this.isMobileScreen();
    window.onresize = this.isMobileScreen;
  },
  data() {
    return {
      isMobile: false,
      isMobileNavOpen: false,
      mobileBrakepoint: 800
    };
  },
  methods: {
    isMobileScreen() {
      if (window.innerWidth < this.mobileBrakepoint) {
        this.isMobile = true;
      }
    },
    closeNav() {
      this.isMobileNavOpen = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
@import '../styles/variables.styl';

.header {
  background-color: colors.black;
  padding: 20px;
  height: 80px;
  overflow: hidden;

  * {
    color: colors.white;
  }

  &__containter {
    max-height: 100%;
    width: containers.large;
    max-width: 96%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    font-family: fonts.serif;
    color: colors.white;
    text-transform: uppercase;
    max-width: 250px;
    font-size: 30px;
    text-align: left;

    a {
      &:after {
        display: none;
      }
    }
  }

  .mobileNavToggle {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 38px;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 200ms ease-in;

    &:hover {
      span {
        &:before {
          transition: all 200ms ease-out;
          transform: translateY(-11px);
        }

        &:after {
          transition: all 200ms ease-out;
          transform: translateY(8px);
        }
      }
    }

    span {
      position: relative;
      height: 3px;
      width: 80%;
      background-color: colors.white;

      &:before, &:after {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        background-color: colors.white;
        transition: all 200ms ease-in;
      }

      &:before {
        transform: translateY(-9px);
      }

      &:after {
        transform: translateY(6px);
      }
    }

    &.open {
      span {
        transition: all 200ms ease-out;
        background-color: colors.black;

        &:before {
          transform: translateY(2px) rotate(45deg);
        }

        &:after {
          transform: translateY(-2px) rotate(-45deg);
        }
      }
    }
  }
}

h3 {
  margin: 40px 0 0;
}

ul#nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;

  &.mobileNav {
    flex-direction: column;
    position: absolute;
    top: 120px;
    left: 0;
    width: 100vw;
    background: colors.black;
    padding: 20px 0;
    z-index: 99;

    li {
      padding: 8px;
    }
  }
}

li {
  margin: 0 10px;
}

a {
  color: colors.black;
  text-decoration: none;
  font-weight: 700;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -16px;
    left: -5px;
    height: 2px;
    width: calc(100% + 10px);
    background-color: colors.white;
    opacity: 0;
    transition: all 300ms ease-in;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &:hover {
    &:after {
      transition: all 300ms ease-out;
      opacity: 1;
      bottom: -8px;
    }
  }
}
</style>
